import React from "react";
import Typist from "react-typist";
import Container from "react-bootstrap/Container";
import Jumbotron from "react-bootstrap/Jumbotron";

const MainBody = React.forwardRef(
  ({ gradient, title, message, icons, link, imgSizeWidth, imgSizeHeight, getInTouchMessage, email }, ref, ) => {
  const [profilePicUrl, setProfilePicUrl] = React.useState("");

  React.useEffect(() => {
      setProfilePicUrl(link);
  }, [link]);

    return (
    <>
      <Jumbotron
        fluid
        id="home"
        style={{
          background: `linear-gradient(136deg,${gradient})`,
          backgroundSize: "1200% 1200%",
        }}
        className="title bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <Container className="text-center">
          <div className="d-none d-lg-block align-self-center">
            {
              <img
                className="rounded-circle"
                src={profilePicUrl}
                alt="profilepicture"
                width={imgSizeWidth}
                height={imgSizeHeight}
              />
            }
          </div>
          <h1 ref={ref} className="display-1">
            {title}
          </h1>
          <Typist className="lead typist" cursor={{ show: false }}>
            {" "}
            {message}
          </Typist>
          <div className="p-5">
            {icons.map((icon, index) => (
              <a
                key={`social-icon-${index}`}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i className={`fab ${icon.image}  fa-3x socialicons`} />
              </a>
            ))}
          </div>

          <div>
            <p className="lead text-center pb-3">
              {getInTouchMessage}, <a href={`mailto:${email}`}>{email}</a>.
            </p>
          </div>

        </Container>
      </Jumbotron>
    </>
    );
  }
);

export default MainBody;
